import {
  FeatureSettingsResponse,
  Feature,
  FeatureSettingsForParticipantInProgramResponse,
  GetMasterFeaturesResponse,
  MasterFeature,
  InstinctFeatureResponse,
} from '../../../models/query';
import{ httpService, QUERY_API_URL } from '../../../../src/config';
import { DateTime } from 'luxon';
import { AxiosRequestConfig } from 'axios';

export const getFeatureSettings = (feature: Feature, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<FeatureSettingsResponse>('/FeatureService/GetFeatureSettings', { feature }, config);

export const checkFeatureAccessibility = (feature: Feature, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<boolean>('/FeatureService/CheckFeatureAccessibility', { feature }, config);

export const isFeatureEnabledForSquare = (feature: Feature, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<boolean>('/FeatureService/IsFeatureEnabledForSquare', { feature }, config);

export const isForceMobileAppEnabledForSquare = (config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<boolean>('/FeatureService/IsForceMobileAppEnabledForSquare', undefined, config);

export const isFeatureEnabledForParticipantInProgram = (feature: Feature, programGuid: string, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<FeatureSettingsForParticipantInProgramResponse>('/FeatureService/IsFeatureEnabledForParticipantInProgram', { feature, programGuid }, config);

export const getMasterFeatures = (config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<GetMasterFeaturesResponse>('/FeatureService/GetMasterFeatures', undefined, config);

export const getMasterFeature = (feature: Feature, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<MasterFeature>('/FeatureService/GetMasterFeature', { feature }, config);

export const getAllEnabledSquareFeatures = (config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<Record<Feature, FeatureSettingsResponse>>('/FeatureService/GetAllEnabledSquareFeatures', undefined, config);

export const getInstinctConfigurationForSquare = (config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<InstinctFeatureResponse>('/FeatureService/GetInstinctConfigurationForSquare', undefined, config);

export const isMasterFeatureEnabledForSquare = (feature: Feature, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<boolean>('/FeatureService/IsMasterFeatureEnabledForSquare', { feature }, config);

export const isFeatureEnabledForAtLeastOneSquareUserHasAccessTo = (feature: Feature, config?: Omit<AxiosRequestConfig, 'url' | 'data' | 'params'>) =>
  httpService.get<boolean>('/FeatureService/IsFeatureEnabledForAtLeastOneSquareUserHasAccessTo', { feature }, config);
